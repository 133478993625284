import { DefaultRootState } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import defaultError from 'src/components/Notifications/DefaultError';
import {
    employerConstructorImageResized,
    employerConstructorResponseTimeOut,
} from 'src/components/Notifications/EmployerConstructor';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import {
    employerConstructorModifyImage,
    PICTURE_RESIZE_MAX_TRIES,
    Status,
    switchImagesActionWrapper,
} from 'src/models/employerConstructor';
import fetcher from 'src/utils/fetcher';

import { EmployerConstructorError } from 'src/components/EmployerConstructor/getErrorType';

const notificationsMap = {
    [EmployerConstructorError.ResponseTimeout]: employerConstructorResponseTimeOut,
    [EmployerConstructorError.ImageResized]: employerConstructorImageResized,
};
type ErrorType = keyof typeof notificationsMap;

const errorTypeHasNotification = (error: string): error is ErrorType => !!notificationsMap[error as ErrorType];

export const CHECK_TIMEOUT_MS = 200;

const CHECK_RESIZE_STATUS_URL = '/employer/constructor/check_resize_status';

declare global {
    interface FetcherGetApi {
        [CHECK_RESIZE_STATUS_URL]: {
            queryParams: { pictureId: number };
            response: { status: Status; path: string };
        };
    }
}

interface PictureInfo {
    pictureId: number;
    widgetId?: number;
}

interface Additional {
    fromUpload?: boolean;
    showErrorAction?: (errorType: string) => AnyAction;
}

const checkResizeStatus =
    (
        { pictureId, widgetId }: PictureInfo,
        { fromUpload, showErrorAction }: Additional,
        addNotification: AddNotification,
        triesLeft = PICTURE_RESIZE_MAX_TRIES
    ): ThunkAction<Promise<void>, DefaultRootState, unknown, AnyAction> =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            const failHandle = (errorType?: string) => {
                dispatch(
                    switchImagesActionWrapper({
                        payload: {
                            resizeStatus: Status.Fail,
                        },
                        widgetId,
                    })
                );
                if (!errorType) {
                    addNotification(defaultError);
                } else if (showErrorAction) {
                    dispatch(showErrorAction(errorType));
                } else if (errorTypeHasNotification(errorType)) {
                    addNotification(notificationsMap[errorType]);
                }
                reject(new Error('Failed to switch images'));
            };

            fetcher.get(CHECK_RESIZE_STATUS_URL, { params: { pictureId } }).then(
                (data) => {
                    if (data.status === Status.Success) {
                        dispatch([
                            employerConstructorModifyImage({
                                path: data.path,
                                pictureId,
                            }),
                            switchImagesActionWrapper({
                                payload: {
                                    resizeStatus: Status.Success,
                                    ...(!fromUpload && {
                                        pictureId,
                                    }),
                                },
                                widgetId,
                            }),
                        ] as unknown as AnyAction);
                        if (!fromUpload) {
                            addNotification(employerConstructorImageResized);
                        }
                        resolve();
                    } else if (data.status === Status.Fail) {
                        failHandle();
                    } else if (!triesLeft) {
                        failHandle(EmployerConstructorError.ResponseTimeout);
                    } else {
                        setTimeout(() => {
                            dispatch(
                                checkResizeStatus(
                                    {
                                        pictureId,
                                        widgetId,
                                    },
                                    {
                                        fromUpload,
                                        showErrorAction,
                                    },
                                    addNotification,
                                    triesLeft - 1
                                )
                            )
                                .then(() => {
                                    resolve();
                                })
                                .catch(() => {
                                    reject(new Error('Check resize status failed'));
                                });
                        }, CHECK_TIMEOUT_MS);
                    }
                },
                () => {
                    failHandle();
                }
            );
        });
    };

export default checkResizeStatus;
