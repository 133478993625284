import { FC } from 'react';
import memoize from 'memoize-one';

import Suggest, { SuggestProps } from 'bloko/blocks/suggest';
import createRemoteDataProvider from 'bloko/blocks/suggest/createRemoteDataProvider';
import createStaticDataProvider from 'bloko/blocks/suggest/createStaticDataProvider';
import { DataProvider, DataProviderItem } from 'bloko/blocks/suggest/types';

import { AddressFromYandexData } from 'Modules/Maps/Utils';
import { EmployerAddress, MetroStation } from 'src/models/employerAddresses.types';

export interface EmployerAddressShort
    extends Pick<EmployerAddress, 'id' | 'displayName' | 'mapData' | 'description' | 'city' | 'street' | 'building'> {
    metroStations?: EmployerAddress['metroStations'];
}

export interface PreparedItem {
    id: number;
    text: string;
    displayName: string;
    description: string;
    source: EmployerAddressShort;
    metroStations: MetroStation[];
}
type EmptyItem = { [K in keyof Omit<PreparedItem, 'text'>]?: undefined } & { text: string };
export type PreparedAddress = PreparedItem | EmptyItem;

export const EMPTY_ITEM: EmptyItem = { text: '' };

export const isNonEmptyAddress = (address: PreparedAddress): address is PreparedItem => {
    return 'id' in address && address.id !== undefined;
};

export const placeholderTemplate = ({
    text,
    metroStations,
    metroStationTrl,
}: {
    text: string;
    metroStations: MetroStation[];
    metroStationTrl: string;
}): string => {
    const separatorWithTrl = `, ${metroStationTrl}`;
    const metroStationsText =
        metroStations && metroStations.length !== 0
            ? `${separatorWithTrl}${metroStations.map((station) => station.name).join(separatorWithTrl)}`
            : '';

    return `${text}${metroStationsText}`;
};

export const prepareAddress = (address: EmployerAddressShort, metroStationTrl: string): PreparedItem => {
    const metroStations = address.metroStations?.metro || [];
    return {
        id: Number(address.id),
        text: placeholderTemplate({ text: address.displayName, metroStations, metroStationTrl }),
        displayName: address.displayName,
        metroStations,
        description: address.description || '',
        source: address,
    };
};

export const prepareAddresses = (
    addresses: EmployerAddressShort[],
    metroStationTrl: string,
    addressIdNotToShow?: number
): PreparedItem[] => {
    if (addressIdNotToShow) {
        return addresses
            .filter((address) => address.id !== addressIdNotToShow)
            .map((address) => prepareAddress(address, metroStationTrl));
    }
    return addresses.map((address) => prepareAddress(address, metroStationTrl));
};

// В блоко типы саджеста слишком строгие, в данной ситуации не подходят
// Будем переводить на магритт, в блоко править нет необходимости
// Пока "прибьем гвоздями"
export const AddressSuggest = Suggest as FC<SuggestProps<PreparedAddress>>;
export const createStaticAdressSuggestProvider = (
    addresses: EmployerAddressShort[],
    metroStationTrl: string,
    addressIdNotToShow?: number
): DataProvider<PreparedItem> =>
    createStaticDataProvider(
        prepareAddresses(addresses, metroStationTrl, addressIdNotToShow) as unknown as DataProviderItem[]
    ) as unknown as DataProvider<PreparedItem>;

const WILDCARD = '%QUERY%';
export const createRemoteAddressSuggestProvider = (
    limit: number,
    employerId: string,
    metroStationTrl: string,
    addressIdNotToShow?: number
): DataProvider<PreparedItem> => {
    const searchUrl = `/employer/addresses/search?limit=${limit}&searchText=${WILDCARD}&employerId=${employerId}`;
    return (query: string) => {
        const dataProvider = createRemoteDataProvider(
            searchUrl,
            WILDCARD
        ) as unknown as DataProvider<EmployerAddressShort>;
        return dataProvider(query).then(({ items }) => ({
            items: prepareAddresses(items, metroStationTrl, addressIdNotToShow),
        }));
    };
};

export const getCurrentAddress = memoize(
    (
        employerAddresses: EmployerAddressShort[],
        id: number | string | undefined,
        metroStationTrl: string
    ): PreparedAddress => {
        const currentAddress = employerAddresses.find((address) => address.id === Number(id));
        if (currentAddress) {
            return prepareAddress(currentAddress, metroStationTrl);
        }
        return EMPTY_ITEM;
    }
);

export interface RawAddress {
    id: string;
    fullAddress: string;
    metroStations: MetroStation[];
    description: string;
    mapData: string;
    city: string;
    street: string;
    building: string;
}

export const prepareNewAddress = (addressObj: AddressFromYandexData): EmployerAddressShort => {
    return {
        id: Number(addressObj.id),
        displayName: addressObj.fullAddress,
        metroStations:
            addressObj?.metroStations.length > 0
                ? {
                      metro: addressObj.metroStations,
                  }
                : undefined,
        description: addressObj.description,
        mapData: addressObj.mapData,
        city: addressObj.city,
        street: addressObj.street,
        building: addressObj.building,
    } as EmployerAddressShort;
};
