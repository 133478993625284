import { useMemo } from 'react';

import { ModalError } from 'bloko/blocks/modal';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { EmployerConstructorError } from 'src/components/EmployerConstructor/getErrorType';
import translation from 'src/components/translation';

/**
 *  Усиленный ModalError для вывода ошибки по типу и с переводом
 */
interface ModalErrorWithHandleErrorsProps {
    visible?: boolean;
    errorType?: string;
}

const TrlKeys = {
    imageInvalidSelection: 'employer.constructor.header.image.invalid.selection',
    responseTimeOut: 'employerconstructor.notify.responseTimeOut',
    defaultError: 'lux.error',
};

const ModalErrorWithHandleErrors: TranslatedComponent<ModalErrorWithHandleErrorsProps> = ({
    errorType,
    visible,
    trls,
}) => {
    const error = useMemo(() => {
        if (!visible) {
            return null;
        }

        switch (errorType) {
            case EmployerConstructorError.InvalidSelectionLocation:
            case EmployerConstructorError.InvalidSelectionSize:
            case EmployerConstructorError.InvalidSelectionRatio:
                return trls[TrlKeys.imageInvalidSelection];
            case EmployerConstructorError.ResponseTimeout:
                return trls[TrlKeys.responseTimeOut];
            default:
                return trls[TrlKeys.defaultError];
        }
    }, [errorType, trls, visible]);

    return <ModalError visible={visible}>{error}</ModalError>;
};

export default translation(ModalErrorWithHandleErrors);
