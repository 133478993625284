import { useMemo, FC } from 'react';

import Button from 'bloko/blocks/button';
import { PlusScaleSmallEnclosedFalse, ArrowsScaleSmallKindDefault, IconColor } from 'bloko/blocks/icon';
import Supports from 'bloko/common/supports';

import DragContainer, { ContainerProps } from 'src/components/EmployerConstructor/drag/Container';
import { DragParams } from 'src/components/EmployerConstructor/drag/DragElement';
import getFileFromEvent from 'src/components/EmployerConstructor/getFileFromEvent';
import ImageSlide from 'src/components/EmployerConstructor/widgets/GalleryWidget/components/ImageSlide';
import SidebarItem from 'src/components/EmployerConstructor/widgets/GalleryWidget/components/SidebarItem';
import {
    EnhancedUploadPicture,
    RemovePicture,
} from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/useActions';
import { PreparedPicture } from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/usePictures';
import FileLoadButton from 'src/components/EmployerConstructor/widgets/components/FileLoadButton';
import { PictureSettings } from 'src/models/employerConstructor/settings';

import styles from './side-bar-container.less';

const IS_TOUCH_SUPPORT = typeof window !== 'undefined' && Supports.touch();

type ActionById = (id: number) => void;

interface SideBarItemContainerProps extends DragParams {
    editImage: ActionById;
    uploadImage: EnhancedUploadPicture;
    removePicture: RemovePicture;
    src: string;
    number: number;
    id: number;
}

const SideBarItemContainer: FC<SideBarItemContainerProps> = ({
    editImage,
    uploadImage,
    removePicture,
    src,
    number,
    id,
    getMovedElementProps,
    dragged,
}) => (
    <div {...(!IS_TOUCH_SUPPORT ? getMovedElementProps?.() : {})}>
        <SidebarItem
            number={number}
            key={id}
            onEdit={() => editImage(id)}
            onUpload={(event) => uploadImage(getFileFromEvent(event), id)}
            onRemove={() => removePicture(id)}
        >
            {IS_TOUCH_SUPPORT && (
                <div className={styles.widgetGalleryItemTouchDrag}>
                    <Button {...getMovedElementProps?.()} disabled={dragged} icon={<ArrowsScaleSmallKindDefault />} />
                </div>
            )}
            <ImageSlide src={src} />
        </SidebarItem>
    </div>
);

interface SideBarContainerProps {
    pictures: PreparedPicture[];
    editImage: ActionById;
    uploadImage: EnhancedUploadPicture;
    removePicture: RemovePicture;
    setOrder: ContainerProps['onDrop'];
    isUploading: boolean;
    canAddPicture?: boolean;
    pictureSettings: PictureSettings;
}

const SideBarContainer: FC<SideBarContainerProps> = ({
    canAddPicture,
    editImage,
    pictures,
    uploadImage,
    removePicture,
    setOrder,
    isUploading,
    pictureSettings,
}) => {
    const sortKeys = useMemo(() => pictures.map(({ id }) => id), [pictures]);

    return (
        <div className={styles.widgetGallerySidebar}>
            <div className={styles.widgetGallerySidebarContent}>
                <DragContainer
                    dropZoneClassName={styles.widgetGalleryItemDropLine}
                    dragElementStubClassName={styles.widgetGalleryItemStub}
                    onDrop={setOrder}
                    sortKeys={sortKeys}
                    scrollByContainer
                >
                    {pictures.map(({ src, id }, index) => (
                        <SideBarItemContainer
                            key={id}
                            src={src}
                            id={id}
                            number={index + 1}
                            editImage={editImage}
                            uploadImage={uploadImage}
                            removePicture={removePicture}
                        />
                    ))}
                </DragContainer>
            </div>
            <div className={styles.widgetGallerySidebarBottomStick}>
                <FileLoadButton
                    stretched
                    isLoading={isUploading}
                    disabled={isUploading || !canAddPicture}
                    icon={<PlusScaleSmallEnclosedFalse initial={IconColor.Gray50} />}
                    inputProps={{
                        accept: pictureSettings.allowedMimeTypes,
                        onChange: (event) => uploadImage(getFileFromEvent(event)),
                    }}
                />
            </div>
        </div>
    );
};

export default SideBarContainer;
