import { useCallback, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import constructorSaveFormFieldTouch from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/constructor_save_form_field_touch';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import scrollToElement from 'Utils/ScrollToElement';
import TextContentEditor from 'src/components/Employer/TextContentEditor';
import WidgetTip from 'src/components/EmployerConstructor/WidgetTip';
import RawUserContent from 'src/components/RawUserContent';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { setDescription } from 'src/models/employer/employerInfo';
import { resetSaveDescriptionErrorType, SaveDescriptionErrorType } from 'src/models/employerConstructor';
import Widget from 'src/models/employerConstructor/widget.types';

import WidgetControls from 'src/components/EmployerConstructor/widgets/WidgetControls';
import WidgetWrapper from 'src/components/EmployerConstructor/widgets/WidgetWrapper';

interface DescriptionWidgetProps {
    editMode?: boolean;
}

const TrlKeys = {
    widgetCompanyDescriptionName: 'employer.constructor.widgetname.companyDescription',
    errors: {
        [SaveDescriptionErrorType.TextTooLong]: 'employer.edit.description.error.text_too_long',
        [SaveDescriptionErrorType.TextTooShort]: 'employer.edit.description.error.text_too_short',
        [SaveDescriptionErrorType.TooManyCaps]: 'employer.edit.description.error.too_many_caps',
        [SaveDescriptionErrorType.Unknown]: 'employer.edit.description.error.unknown',
    },
};

const DescriptionWidget: TranslatedComponent<DescriptionWidgetProps> = ({ trls, editMode }) => {
    const dispatch = useDispatch();
    const description = useSelector((state) => state.employerInfo.description || '');
    const saveDescriptionErrorType = useSelector((state) => state.employerConstructor.saveDescriptionErrorType);
    const viewReady = !!description;
    const widgetWrapperRef = useRef(null);

    useEffect(() => {
        if (saveDescriptionErrorType) {
            scrollToElement(widgetWrapperRef.current, { centered: false, topOffset: 0 });
        }
    }, [saveDescriptionErrorType]);

    const view = useCallback(() => {
        return (
            <Text size={TextSize.Large}>
                <RawUserContent content={description} />
            </Text>
        );
    }, [description]);

    const edit = useCallback(() => {
        return (
            <WidgetTip code={Widget.Description}>
                <WidgetControls
                    edit={false}
                    upload={false}
                    drag={false}
                    remove={false}
                    name={trls[TrlKeys.widgetCompanyDescriptionName]}
                />
                <TextContentEditor
                    value={description}
                    onChange={(value: string | null) => value && dispatch(setDescription(value))}
                    onFocus={() => {
                        dispatch(resetSaveDescriptionErrorType());
                        constructorSaveFormFieldTouch({ fieldName: 'company_description' });
                    }}
                    invalid={!!saveDescriptionErrorType}
                />
            </WidgetTip>
        );
    }, [description, dispatch, saveDescriptionErrorType, trls]);

    const errorTrl = saveDescriptionErrorType ? trls[TrlKeys.errors[saveDescriptionErrorType]] : undefined;

    return (
        <WidgetWrapper
            edit={edit}
            view={view}
            editMode={editMode}
            viewReady={viewReady}
            ref={widgetWrapperRef}
            invalid={errorTrl}
        />
    );
};

export default translation(DescriptionWidget);
