import { FC, PointerEventHandler } from 'react';
import classnames from 'classnames';

import { DraggedPointType } from 'src/components/ImageCropPopup/Points/pointType';

import styles from './circle.less';

interface CircleProps {
    type: DraggedPointType;
    onPointerDown: PointerEventHandler<HTMLDivElement>;
}

const circleStyleMap = {
    [DraggedPointType.LeftTop]: styles.circlePointLeftTop,
    [DraggedPointType.RightTop]: styles.circlePointRightTop,
    [DraggedPointType.RightBottom]: styles.circlePointRightBottom,
    [DraggedPointType.LeftBottom]: styles.circlePointLeftBottom,
};

const Circle: FC<CircleProps> = ({ type, onPointerDown }) => {
    return <div onPointerDown={onPointerDown} className={classnames(styles.circlePoint, circleStyleMap[type])} />;
};

export default Circle;
