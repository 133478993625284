import { FC, PropsWithChildren } from 'react';
import { Transition } from 'react-transition-group';

const ErrorComponent: FC<PropsWithChildren<{ show: boolean }>> = ({ children, show }) => {
    return (
        <Transition in={show} timeout={100} mountOnEnter unmountOnExit appear>
            {(state) => (
                <div className={`validation-error validation-error_${state}`} data-qa="createvacancy__error">
                    {children}
                </div>
            )}
        </Transition>
    );
};

export default ErrorComponent;
