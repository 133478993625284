import { FC } from 'react';

interface MovedElementStubProps {
    dragElement?: HTMLDivElement;
    stubClassName?: string;
}

const MovedElementStub: FC<MovedElementStubProps> = ({ dragElement, stubClassName }) => {
    if (!dragElement) {
        return null;
    }
    const bounds = dragElement.getBoundingClientRect();
    return (
        <div
            className={stubClassName}
            style={{
                height: bounds.height,
            }}
        />
    );
};

export default MovedElementStub;
