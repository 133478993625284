import { FC } from 'react';

import MetroPin from 'bloko/blocks/metroPin';

import styles from './add-address-modal.less';

interface AddressMetroItemProps {
    color?: string;
}

const AddressMetroPin: FC<AddressMetroItemProps> = ({ color }) => {
    return (
        // TODO: В рамках PORTFOLIO-29826 заменить на компонент из magritte-ui
        <div className={styles.addressMetroItem}>
            <MetroPin color={color} />
        </div>
    );
};

export default AddressMetroPin;
