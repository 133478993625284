import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './add-address-modal.less';

interface AddressModalSidebarProps extends PropsWithChildren {
    showSidebar?: boolean;
}

const AddressModalSidebar: FC<AddressModalSidebarProps> = ({ children, showSidebar = false }) => {
    return (
        <div
            className={classnames(styles.addressModalSidebar, {
                [styles.addressModalSidebarShown]: showSidebar,
            })}
        >
            {children}
        </div>
    );
};

export default AddressModalSidebar;
