import { FC, ReactNode, ComponentPropsWithoutRef } from 'react';

import { ButtonKind } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';

import FileLoadButton from 'src/components/EmployerConstructor/widgets/components/FileLoadButton';
import WidgetEditDescription from 'src/components/EmployerConstructor/widgets/components/WidgetEditDescription';
import WidgetEditDescriptionIcon, {
    WidgetEditDescriptionIconType,
} from 'src/components/EmployerConstructor/widgets/components/WidgetEditDescriptionIcon';
import WidgetEditDescriptionText from 'src/components/EmployerConstructor/widgets/components/WidgetEditDescriptionText';

interface LoadAreaProps {
    className?: string;
    hintText?: ReactNode;
    buttonText?: ReactNode;
    isLoading?: boolean;
    disabled?: boolean;
    inputProps?: ComponentPropsWithoutRef<'input'>;
}

const LoadArea: FC<LoadAreaProps> = ({ hintText, className, buttonText, isLoading, disabled, inputProps }) => (
    <WidgetEditDescription className={className}>
        <WidgetEditDescriptionText>
            {hintText}
            <Gap top>
                <FileLoadButton
                    kind={ButtonKind.Success}
                    isLoading={isLoading}
                    disabled={disabled || isLoading}
                    inputProps={inputProps}
                >
                    {buttonText}
                </FileLoadButton>
            </Gap>
        </WidgetEditDescriptionText>
        <WidgetEditDescriptionIcon iconType={WidgetEditDescriptionIconType.Image} />
    </WidgetEditDescription>
);

export default LoadArea;
