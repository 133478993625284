import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './widget-edit-description.less';

export enum WidgetEditDescriptionIconType {
    Image = 'image',
    Video = 'video',
}

const iconClassMap = {
    [WidgetEditDescriptionIconType.Image]: [styles.widgetEditDescriptionIconImage],
    [WidgetEditDescriptionIconType.Video]: [styles.widgetEditDescriptionIconVideo],
};

interface WidgetEditDescriptionIconProps {
    iconType: WidgetEditDescriptionIconType;
}

const WidgetEditDescriptionIcon: FC<PropsWithChildren<WidgetEditDescriptionIconProps>> = ({ iconType, children }) => (
    <div className={classNames(styles.widgetEditDescriptionIcon, iconClassMap[iconType])}>{children}</div>
);

export default WidgetEditDescriptionIcon;
