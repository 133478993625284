import { ChangeEvent } from 'react';

export type MixedEvent = ChangeEvent<HTMLInputElement> | File[];

const isChangeEvent = (event: MixedEvent): event is ChangeEvent<HTMLInputElement> => Object.hasOwn(event, 'target');

// Используется как с DropZone, так и с простыми input/file
const getFileFromEvent = (event: MixedEvent): File => {
    let file;
    if (isChangeEvent(event)) {
        file = event.target.files?.[0];
        event.target.value = '';
    } else {
        file = event[0];
    }
    if (!file) {
        throw new Error('No file provided for upload');
    }
    return file;
};

export default getFileFromEvent;
