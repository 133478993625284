let CKEditorPromise;

function editorPromise() {
    if (!CKEditorPromise) {
        CKEditorPromise = new Promise((resolve, reject) => {
            if (!window) {
                reject();
            } else if (!window.CKEDITOR) {
                const script = document.createElement('script');
                script.onload = () => resolve();
                script.src = `${window.globalVars.staticHost}/ckeditor/ckeditor.js?v=1.2.0`;
                document.querySelector('head').appendChild(script);
            }
        });
    }
    return CKEditorPromise;
}

export default editorPromise;
