import { forwardRef, ForwardRefRenderFunction } from 'react';
import classnames from 'classnames';

import ErrorComponent from 'src/components/EmployerConstructor/ErrorComponent';
import { useSelector } from 'src/hooks/useSelector';

import styles from './employer-constructor-widgets.less';

interface WidgetWrapperProps {
    edit: () => void;
    view: () => void;
    editMode?: boolean;
    viewReady?: boolean;
    dragged?: boolean;
    invalid?: string;
}

const WidgetWrapper: ForwardRefRenderFunction<HTMLDivElement, WidgetWrapperProps> = (
    { edit, view, editMode, viewReady, dragged, invalid },
    ref
) => {
    const webViewMode = useSelector(({ employerConstructorWebViewMode }) => employerConstructorWebViewMode);

    if (!viewReady && !editMode) {
        return null;
    }
    return (
        <>
            <div
                className={classnames(styles.employerConstructorWidget, {
                    [styles.employerConstructorWidgetViewMode]: !editMode,
                    [styles.employerConstructorWidgetWebview]: webViewMode,
                    [styles.employerConstructorWidgetDragged]: dragged,
                })}
                ref={ref}
            >
                <>
                    {editMode && edit()}
                    {viewReady && !editMode && view()}
                </>
            </div>
            <ErrorComponent show={!!invalid}>{invalid}</ErrorComponent>
        </>
    );
};

export default forwardRef(WidgetWrapper);
