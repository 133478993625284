import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './widget-edit-description.less';

interface WidgetEditDescriptionProps {
    className?: string;
}

const WidgetEditDescription: FC<PropsWithChildren<WidgetEditDescriptionProps>> = ({ className, children }) => (
    <div className={classNames(styles.widgetEditDescription, className)}>{children}</div>
);

export default WidgetEditDescription;
