import { GeoObjectsContext } from 'Modules/Maps/maps.types';

export type Cities = Record<number, string[]>[];

export interface YandexCity {
    address: string;
    city: string;
    fullAddress: string;
    context: GeoObjectsContext;
    coordinates: number[];
    localityName: string;
}

export interface Coordinate {
    lat: number;
    lng: number;
}

export interface MetroStationLine {
    '@cityId': number;
    '@id': number;
    '@name': string;
    '@translit': string;
    color: string;
    id: number;
    name: string;
}

export interface MetroStation {
    '@cityId': number;
    '@id': number;
    '@isAlias': boolean;
    '@name': string;
    '@order': number;
    '@translit': string;
    id: number;
    lat: number;
    lng: number;
    name: string;
    line: MetroStationLine;
}

export interface Metro {
    id: number;
    name?: string;
    text: string;
    line?: MetroStationLine;
    lat?: number;
    lng?: number;
    cityId?: number;
}

export interface MapData {
    points: { center: Coordinate & { zoom: number }; marker: Coordinate };
}

export interface AddressResponse {
    '@canEdit': boolean;
    '@disabled': boolean;
    '@id': number;
    building: string;
    city: string;
    description: string;
    displayName: string;
    mapData: string;
    marker: { '@lat': number; '@lng': number };
    metroStations: { '@lat': number; '@lng': number; metro: MetroStation[] };
    street: string;
}

export type AddressDuplicateType = 'use' | 'show';

export enum AddressState {
    Prepared,
    Init,
    Search,
    Address,
}

export interface FormValues {
    fullAddress: string;
    renderedAddress: string;
    addressLine: string;
    city: string;
    street: string;
    building: string;
    description: string;
    mapData: string;
    metroStationId: string[];
    metroStations: Metro[];
    marker: string;
}
