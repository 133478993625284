/* eslint-disable promise/catch-or-return */
import utils from 'Modules/Maps/Utils';

const RESULTS_COUNT = 20;
export default {
    geocode(request, options) {
        const promise = new window.ymaps.vow.defer();
        const results = options.results || RESULTS_COUNT;
        let geoObjects;
        const response = {
            metaData: {
                geocoder: {
                    request,
                    found: results,
                    results,
                    skip: options.skip || 0,
                },
            },
            geoObjects: new window.ymaps.GeoObjectCollection(),
        };
        const processYMapsResults = function (firstResult, result) {
            if (result) {
                result.geoObjects.each((object) => {
                    if (
                        utils.isPartEqual('ThoroughfareName', object, firstResult) &&
                        !utils.isPartEqual('text', object, firstResult)
                    ) {
                        geoObjects.add(object);
                    }
                });
            }
            geoObjects.each((item) => {
                const geoObject = utils.convertGeoObject(item);
                if (
                    geoObject.LocalityName ||
                    geoObject.DependentLocalityName ||
                    geoObject.AdministrativeAreaName ||
                    geoObject.SubAdministrativeAreaName
                ) {
                    response.geoObjects.add(item);
                }
            });
            promise.resolve(response);
        };
        window.ymaps.geocode(request, { results }).then((result) => {
            geoObjects = result.geoObjects;
            const firstResult = result.geoObjects.get(0);
            // Если яндекс карты выдали один объект на отправленный запрос,
            // берем координаты объекта и ищем места рядом
            if (geoObjects.getLength() === 1) {
                window.ymaps
                    .geocode(firstResult.geometry.getCoordinates(), { results: RESULTS_COUNT })
                    .then(processYMapsResults.bind(null, firstResult));
            } else {
                // Если геокодер вернул несколько результатов - отрисовываем их
                processYMapsResults(firstResult);
            }
        });
        return promise.promise();
    },
};
