import { FC } from 'react';

import { ChevronScaleSmallKindRight, ChevronScaleSmallKindLeft } from 'bloko/blocks/icon';

import styles from './switcher.less';

interface SwitcherProps {
    count: number;
    value: number;
    onChange: (newCount: number) => void;
}

const Switcher: FC<SwitcherProps> = ({ count, value, onChange }) => (
    <div className={styles.widgetGallerySwitcher}>
        <button
            data-qa="widget-gallery-switcher-button-left"
            className={styles.widgetGallerySwitcherButton}
            onClick={() => onChange(value <= 1 ? count : value - 1)}
        >
            <ChevronScaleSmallKindLeft />
        </button>
        <span>
            {value} / {count}
        </span>
        <button
            data-qa="widget-gallery-switcher-button-right"
            className={styles.widgetGallerySwitcherButton}
            onClick={() => onChange(value >= count ? 1 : value + 1)}
        >
            <ChevronScaleSmallKindRight />
        </button>
    </div>
);

export default Switcher;
