import { FC } from 'react';
import classNames from 'classnames';

import { PLUGINS, PLUGINS_ACTION, EDITOR_TOOLBAR_CONSTRUCTOR, AUTO_GROW } from 'Modules/CKEditor/common-settings';
import Editor, { EditorComponentProps } from 'src/components/Editor';

import styles from './text-content-editor.less';

const EDITOR_HEIGHT = 135;

interface TextContentEditorProps
    extends Pick<EditorComponentProps, 'value' | 'onChange' | 'onFocus' | 'onBlur' | 'invalid'> {
    overlapFrame?: boolean;
}

const TextContentEditor: FC<TextContentEditorProps> = ({ overlapFrame, ...props }) => (
    <div
        data-qa="text-content-editor"
        className={classNames(styles.employerTextEditor, {
            [styles.employerTextEditorOverlap]: overlapFrame,
        })}
    >
        <Editor
            toolbar={EDITOR_TOOLBAR_CONSTRUCTOR}
            formatTags={'h2;h3;p'}
            height={EDITOR_HEIGHT}
            plugins={[
                {
                    name: PLUGINS.AUTO_GROW,
                    action: PLUGINS_ACTION.ADD,
                    props: {
                        [AUTO_GROW.MIN_HEIGHT_PROPERTY]: EDITOR_HEIGHT,
                        [AUTO_GROW.MAX_HEIGHT_PROPERTY]: 500,
                        [AUTO_GROW.BOTTOM_SPACE_PROPERTY]: 50,
                    },
                },
                {
                    name: PLUGINS.RESIZE,
                    action: PLUGINS_ACTION.REMOVE,
                },
            ]}
            strictHeight={false}
            {...props}
        />
    </div>
);

export default TextContentEditor;
