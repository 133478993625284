import { FC, PropsWithChildren, ComponentPropsWithoutRef } from 'react';

import Button, { ButtonProps } from 'bloko/blocks/button';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';

interface FileLoadButtonProps extends Partial<ButtonProps> {
    isLoading?: boolean;
    disabled?: boolean;
    inputProps?: ComponentPropsWithoutRef<'input'>;
}

const FileLoadButton: FC<PropsWithChildren<FileLoadButtonProps>> = ({
    inputProps,
    children,
    isLoading,
    disabled,
    ...buttonProps
}) => (
    <label>
        <Button
            {...buttonProps}
            disabled={disabled}
            Element="span"
            loading={isLoading && <Loading initial={LoadingColor.Gray80} scale={LoadingScale.Small} />}
        >
            {!disabled && <input type="file" data-qa="upload-widget-image" className="g-hidden" {...inputProps} />}
            {children}
        </Button>
    </label>
);

export default FileLoadButton;
