import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import resizeImageAction, { ResizeParams } from 'src/components/EmployerConstructor/resizeImage';
import uploadImageAction from 'src/components/EmployerConstructor/uploadImage';
import { useNotification } from 'src/components/Notifications/Provider';
import { useSelector } from 'src/hooks/useSelector';
import {
    EmployerConstructorGalleryWidget,
    employerConstructorModifyWidget,
    GalleryPictureItem,
    setModalError,
} from 'src/models/employerConstructor';
import { PictureType } from 'src/models/employerConstructor/settings';

import { SetCropPictureId } from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/useImageCrop';

const addPictureById = (items: GalleryPictureItem[], pictureId: number) => [
    ...items,
    {
        pictureId,
    },
];

const replacePictureById = (items: GalleryPictureItem[], val1: number | null | undefined, val2: number) =>
    items.map((pictureItem) =>
        pictureItem.pictureId !== val1
            ? pictureItem
            : {
                  ...pictureItem,
                  pictureId: val2,
              }
    );

const removePictureById = (items: GalleryPictureItem[], pictureId: number) => {
    const cloneItems = [...items];
    const position = cloneItems.map((picture) => picture.pictureId).indexOf(pictureId);

    cloneItems.splice(position, 1);

    return cloneItems;
};

export interface EnhancedUploadPicture {
    (imageFile: File, typedPictureId?: number | null, isDragNDrop?: boolean): Promise<void>;
}
export interface RemovePicture {
    (pictureId: number): void;
}
type SetWidgetItemsByIds = (pictureIds: number[]) => void;
type ShowResizePictureInterface = (pictureId: number, typedPictureId?: number | null) => void;
export interface ResizePictureNoChanges {
    (pictureId: number, typedPictureId?: number | null): void;
}
export interface EnhancedResizePicture {
    (pictureId: number, typedPictureId: number | null | undefined, resizeParams: ResizeParams): Promise<void>;
}

interface UseActionsParams {
    widgetId: number;
    setCropPictureId: SetCropPictureId;
}
interface UseActionsResult {
    isUploading: boolean;
    isResizing: boolean;
    enhancedUploadPicture: EnhancedUploadPicture;
    enhancedResizePicture: EnhancedResizePicture;
    resizePictureNoChanges: ResizePictureNoChanges;
    removePicture: RemovePicture;
    setWidgetItemsByIds: SetWidgetItemsByIds;
    showResizePictureInterface: ShowResizePictureInterface;
}

export default ({ widgetId, setCropPictureId }: UseActionsParams): UseActionsResult => {
    const [isUploading, setIsUploading] = useState(false);
    const [isResizing, setIsResizing] = useState(false);
    const widgetData = useSelector(
        ({ employerConstructor }) =>
            employerConstructor.widgets.find(({ id }) => widgetId === id) as EmployerConstructorGalleryWidget
    );
    const widgetItems = useMemo(() => widgetData?.items || [], [widgetData]);
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const showResizePictureInterface: ShowResizePictureInterface = useCallback(
        (pictureId, typedPictureId) => {
            setCropPictureId(pictureId, typedPictureId);
        },
        [setCropPictureId]
    );

    const resizePictureNoChanges: ResizePictureNoChanges = useCallback(
        (pictureId, typedPictureId) => {
            const items =
                widgetItems.some(({ pictureId: itemPictureId }) => pictureId === itemPictureId) || typedPictureId
                    ? replacePictureById(widgetItems, typedPictureId, pictureId)
                    : addPictureById(widgetItems, pictureId);

            dispatch(
                employerConstructorModifyWidget({
                    id: widgetId,
                    items,
                })
            );
        },
        [dispatch, widgetId, widgetItems]
    );

    const enhancedResizePicture: EnhancedResizePicture = useCallback(
        (pictureId, typedPictureId, resizeParams) => {
            setIsResizing(true);
            return dispatch(
                resizeImageAction(
                    {
                        pictureId,
                        resizeParams,
                        widgetId,
                    },
                    setModalError,
                    addNotification
                )
            )
                .then(({ pictureId: resizedPictureId }) => {
                    const items =
                        widgetItems.some(({ pictureId: itemPictureId }) => pictureId === itemPictureId) ||
                        typedPictureId
                            ? replacePictureById(widgetItems, typedPictureId || pictureId, resizedPictureId)
                            : addPictureById(widgetItems, resizedPictureId);

                    dispatch(
                        employerConstructorModifyWidget({
                            id: widgetId,
                            items,
                        })
                    );
                    setIsResizing(false);
                })
                .catch(() => {
                    setIsResizing(false);
                });
        },
        [addNotification, dispatch, widgetId, widgetItems]
    );

    const enhancedUploadPicture: EnhancedUploadPicture = useCallback(
        (imageFile, typedPictureId) => {
            setIsUploading(true);
            return dispatch(
                uploadImageAction(
                    {
                        file: imageFile,
                        pictureType: PictureType.GalleryWidget,
                        widgetId,
                        hasAlreadyImage: !!typedPictureId,
                    },
                    addNotification
                )
            )
                .then(({ pictureId }) => {
                    showResizePictureInterface(pictureId, typedPictureId);
                    setIsUploading(false);
                })
                .catch(() => {
                    setIsUploading(false);
                });
        },
        [addNotification, dispatch, showResizePictureInterface, widgetId]
    );

    const removePicture: RemovePicture = useCallback(
        (pictureId) => {
            dispatch(
                employerConstructorModifyWidget({
                    id: widgetId,
                    items: removePictureById(widgetItems, pictureId),
                })
            );
        },
        [dispatch, widgetId, widgetItems]
    );

    const setWidgetItemsByIds: SetWidgetItemsByIds = useCallback(
        (pictureIds) => {
            dispatch(
                employerConstructorModifyWidget({
                    id: widgetId,
                    items: pictureIds.map((pictureId) => ({
                        pictureId,
                    })),
                })
            );
        },
        [dispatch, widgetId]
    );

    return {
        isUploading,
        isResizing,
        enhancedUploadPicture,
        enhancedResizePicture,
        resizePictureNoChanges,
        removePicture,
        setWidgetItemsByIds,
        showResizePictureInterface,
    };
};
