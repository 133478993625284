import { FC } from 'react';

interface RawUserContentProps {
    content: string;
    dataQa?: string;
}

const RawUserContent: FC<RawUserContentProps> = ({ content, dataQa }) => {
    return <div className="g-user-content" data-qa={dataQa} dangerouslySetInnerHTML={{ __html: content }} />;
};

export default RawUserContent;
