import { FC } from 'react';

import styles from './image-slide.less';

interface ImageSlideProps {
    src: string;
}

const ImageSlide: FC<ImageSlideProps> = ({ src }) => (
    <div className={styles.widgetGalleryImage}>
        <img
            src={src}
            draggable={false}
            className={styles.widgetGalleryImageImage}
            data-qa="widget-gallery-image__image"
        />
    </div>
);

export default ImageSlide;
