import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import usePictureSettings from 'src/components/EmployerConstructor/usePictureSettings';
import {
    EnhancedResizePicture,
    ResizePictureNoChanges,
} from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/useActions';
import ImageCropPopup, { ImageCropResultHandler } from 'src/components/ImageCropPopup';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { setModalError, EMPTY_ERROR_STATE } from 'src/models/employerConstructor';
import { PictureType } from 'src/models/employerConstructor/settings';

const TrlKeys = {
    title: 'employer.constructor.widget.image.cropimage.title',
    description: 'employer.constructor.widget.image.cropimage.description',
    save: 'employer.constructor.cropimage.button.save',
    cancel: 'employer.constructor.cropimage.button.cancel',
};

export interface ImageCropPopupContainerProps {
    visible: boolean;
    pictureId: number;
    typedPictureId?: number | null;
    onClose: () => void;
    resizeInProgress?: boolean;
    onSave: EnhancedResizePicture;
    noChanges: ResizePictureNoChanges;
}

const usePictureInfo = (pictureId: number) => {
    const pictureInfo = useSelector(({ employerConstructor }) =>
        employerConstructor.images.find((image) => image.pictureId === pictureId)
    );
    if (!pictureInfo) {
        throw new Error('Picture info not found');
    }
    return pictureInfo;
};

const ImageCropPopupContainer: TranslatedComponent<ImageCropPopupContainerProps> = ({
    visible,
    onSave,
    onClose,
    noChanges,
    pictureId,
    typedPictureId,
    trls,
    resizeInProgress,
}) => {
    const dispatch = useDispatch();
    const error = useSelector(({ employerConstructor }) => employerConstructor.modalError);
    const pictureInfo = usePictureInfo(pictureId);
    const pictureSettings = usePictureSettings(PictureType.GalleryWidget);
    const { originalWidth, originalHeight } = pictureInfo;

    const enhancedOnSave: ImageCropResultHandler = useCallback(
        async (cropParams) => {
            if (cropParams.noChanges) {
                noChanges(pictureId, typedPictureId);
                onClose();
                return;
            }

            dispatch(setModalError(EMPTY_ERROR_STATE));
            await onSave(pictureId, typedPictureId, {
                ...cropParams.absoluteSizes,
                originalWidth,
                originalHeight,
            });
            onClose();
        },
        [dispatch, onSave, pictureId, typedPictureId, originalWidth, originalHeight, onClose, noChanges]
    );

    const imageCropSettings = useMemo(
        () => ({
            src: pictureInfo.originalPath,
            stateX: pictureInfo.selectionLeft,
            stateY: pictureInfo.selectionTop,
            stateWidth: pictureInfo.selectionWidth,
            stateHeight: pictureInfo.selectionHeight,
            originalWidth: pictureInfo.originalWidth,
            originalHeight: pictureInfo.originalHeight,
            ratio: pictureSettings.widthHeightRatio,
            minimumWidth: pictureSettings.minWidth,
            minimumHeight: pictureSettings.minHeight,
        }),
        [
            pictureInfo.originalHeight,
            pictureInfo.originalPath,
            pictureInfo.originalWidth,
            pictureInfo.selectionHeight,
            pictureInfo.selectionLeft,
            pictureInfo.selectionTop,
            pictureInfo.selectionWidth,
            pictureSettings.minHeight,
            pictureSettings.minWidth,
            pictureSettings.widthHeightRatio,
        ]
    );

    return (
        <ImageCropPopup
            error={error}
            onClose={onClose}
            onDragStop={() => error && dispatch(setModalError(EMPTY_ERROR_STATE))}
            onSave={enhancedOnSave}
            visible={visible}
            resizeInProgress={resizeInProgress}
            imageCropSettings={imageCropSettings}
            title={trls[TrlKeys.title]}
            description={trls[TrlKeys.description]}
            save={trls[TrlKeys.save]}
            cancel={trls[TrlKeys.cancel]}
        />
    );
};

export default translation(ImageCropPopupContainer);
