import { FC } from 'react';

import SlidesContainer from 'src/components/EmployerConstructor/widgets/GalleryWidget/containers/SlidesContainer';
import { PreparedPicture } from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/usePictures';
import useSlides from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/useSlides';

import styles from './gallery-widget.less';

interface ViewModeContainerProps {
    pictures: PreparedPicture[];
}

const ViewModeContainer: FC<ViewModeContainerProps> = ({ pictures }) => {
    const [slides, activeSlide, setActiveSlide] = useSlides(pictures);

    return (
        <div className={styles.widgetGallery} data-qa="widget-gallery">
            <SlidesContainer slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
        </div>
    );
};

export default ViewModeContainer;
