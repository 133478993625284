import { useCallback, useState, useRef, FC } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'src/hooks/useSelector';
import {
    employerConstructorUpdateWidgetsOrder,
    employerConstructorAddWidget,
    AnyWidget,
} from 'src/models/employerConstructor';
import Widget from 'src/models/employerConstructor/widget.types';

import AddWidget from 'src/components/EmployerConstructor/AddWidget';
import DragContainer from 'src/components/EmployerConstructor/drag/Container';
import AddressWidget from 'src/components/EmployerConstructor/widgets/AddressWidget';
import DescriptionWidget from 'src/components/EmployerConstructor/widgets/DescriptionWidget';
import GalleryWidget from 'src/components/EmployerConstructor/widgets/GalleryWidget';
import ImageWidget from 'src/components/EmployerConstructor/widgets/ImageWidget';
import SeparatorWidget from 'src/components/EmployerConstructor/widgets/SeparatorWidget';
import TextWidget from 'src/components/EmployerConstructor/widgets/TextWidget';
import VideoWidget from 'src/components/EmployerConstructor/widgets/VideoWidget';

import styles from 'src/components/EmployerConstructor/widgets/employer-constructor-widgets.less';

interface WidgetsListProps {
    editMode?: boolean;
}

const WidgetsList: FC<WidgetsListProps> = ({ editMode }) => {
    const widgets = useSelector((state) => state.employerConstructor.widgets);
    const previewModeWithBranding = useSelector((state) => state.employerConstructor.previewModeWithBranding);
    const widgetsOrder = useSelector((state) => state.employerConstructor.widgetsOrder);

    const dispatch = useDispatch();

    const [addressesLoaded, setAddressesLoaded] = useState(false);
    const addressesFetching = useRef(false);

    const widgetsMap = useCallback(
        (sortIds?: number[]) => {
            const widgetsList = [...widgets];
            if (sortIds) {
                widgetsList.sort((widgetA, widgetB) => {
                    return sortIds.indexOf(widgetA.id) - sortIds.indexOf(widgetB.id);
                });
            }

            return (widgetsList as AnyWidget[])
                .map((widget) => {
                    switch (widget.type) {
                        case Widget.Gallery:
                            return <GalleryWidget key={widget.id} {...widget} editMode={editMode} />;
                        case Widget.Picture:
                            return <ImageWidget key={widget.id} {...widget} editMode={editMode} />;
                        case Widget.Address:
                            return (
                                <AddressWidget
                                    key={widget.id}
                                    {...widget}
                                    editMode={editMode}
                                    addressesLoaded={addressesLoaded}
                                    setAddressesLoaded={setAddressesLoaded}
                                    addressesFetching={addressesFetching}
                                />
                            );
                        case Widget.Text:
                            return <TextWidget key={widget.id} {...widget} editMode={editMode} />;
                        case Widget.Video:
                            return <VideoWidget key={widget.id} {...widget} editMode={editMode} />;
                        case Widget.Separator:
                            return <SeparatorWidget key={widget.id} {...widget} editMode={editMode} />;
                    }
                    return null;
                })
                .filter((element): element is JSX.Element => !!element);
        },
        [widgets, editMode, addressesLoaded]
    );

    const widgetsRender = () => {
        return editMode ? (
            <DragContainer
                dropZoneClassName={styles.employerConstructorWidgetsDropLine}
                dragElementStubClassName={styles.employerConstructorWidgetsDragElementStub}
                onDrop={(keys) => dispatch(employerConstructorUpdateWidgetsOrder(keys))}
                sortKeys={widgetsOrder || widgets.map(({ id }) => id)}
            >
                {widgetsMap()}
            </DragContainer>
        ) : (
            widgetsMap(widgetsOrder)
        );
    };

    return (
        <div data-qa="employer-constructor-widgets-container">
            <DescriptionWidget editMode={editMode} />
            {(editMode || previewModeWithBranding) && !!widgets.length && widgetsRender()}
            {editMode && (
                <AddWidget widgets={widgets} onClick={(widget) => dispatch(employerConstructorAddWidget(widget))} />
            )}
        </div>
    );
};

export default WidgetsList;
