import { FC, PropsWithChildren } from 'react';

import styles from './height-by-ratio.less';

const HeightByRatio: FC<PropsWithChildren<{ ratio: number }>> = ({ children, ratio }) => (
    <div
        className={styles.widgetHeightByRatio}
        style={{
            padding: `calc((100% / ${ratio}) / 2) 0`,
        }}
    >
        <div className={styles.widgetHeightByRatioContent}>{children}</div>
    </div>
);

export default HeightByRatio;
