import { ChangeEventHandler, MouseEventHandler, PropsWithChildren, FC } from 'react';

import { TrashScaleSmall, PenSquareScaleSmall, CameraScaleSmall, IconColor } from 'bloko/blocks/icon';
import Text, { TextSize } from 'bloko/blocks/text';

import styles from './sidebar-item.less';

interface SidebarItemProps {
    number?: number;
    onUpload?: ChangeEventHandler<HTMLInputElement>;
    onEdit?: MouseEventHandler<HTMLButtonElement>;
    onRemove?: MouseEventHandler<HTMLButtonElement>;
}

const SidebarItem: FC<PropsWithChildren<SidebarItemProps>> = ({ children, number, onUpload, onEdit, onRemove }) => (
    <div className={styles.widgetGalleryItem}>
        <div className={styles.widgetGalleryItemNumber}>
            <Text size={TextSize.Small}>{number}</Text>
        </div>
        <div className={styles.widgetGalleryItemImage}>{children}</div>
        <div className={styles.widgetGalleryItemActions}>
            <label>
                <span className={styles.widgetGalleryItemButton}>
                    <CameraScaleSmall initial={IconColor.Gray50} />
                    <input data-qa="gallery-widget-file-input" type="file" className="g-hidden" onChange={onUpload} />
                </span>
            </label>
            <button data-qa={'gallery-widget-button-edit'} className={styles.widgetGalleryItemButton} onClick={onEdit}>
                <PenSquareScaleSmall initial={IconColor.Gray50} />
            </button>
            <button
                data-qa={'gallery-widget-button-remove'}
                className={styles.widgetGalleryItemButton}
                onClick={onRemove}
            >
                <TrashScaleSmall initial={IconColor.Gray50} />
            </button>
        </div>
    </div>
);

export default SidebarItem;
