import { FC, PropsWithChildren, RefObject } from 'react';

import styles from './widget-controls.less';

const WidgetControlsButtonWrapper: FC<PropsWithChildren<{ contentRef?: RefObject<HTMLDivElement> }>> = ({
    children,
    contentRef,
}) => (
    <div className={styles.widgetControlsButtonWrapper} ref={contentRef}>
        {children}
    </div>
);

export default WidgetControlsButtonWrapper;
