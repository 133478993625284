import { useMemo } from 'react';

import urlParser from 'bloko/common/urlParser';

import { useCdnHost } from 'src/hooks/useCdnImageHost';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { EmployerConstructorImage, GalleryPictureItem } from 'src/models/employerConstructor';

const DEFAULT_CROP_WIDTH = 690;
const DEFAULT_CROP_HEIGHT = 397;

const addCropParam = (url: string, isZp: boolean): string => {
    const parsedUrl = urlParser(url);
    if (isZp) {
        parsedUrl.params.crop = `${DEFAULT_CROP_WIDTH}x${DEFAULT_CROP_HEIGHT}`;
    }

    return parsedUrl.href;
};

export interface PreparedPicture {
    id: number;
    src: string;
    originalWidth: number;
    originalHeight: number;
    originalPath?: string | null | undefined;
    selectionLeft: number;
    selectionTop: number;
    selectionWidth: number;
    selectionHeight: number;
}

const usePictures = (items?: GalleryPictureItem[]): PreparedPicture[] => {
    const cdnHost = useCdnHost();
    const isZp = useIsZarplataPlatform();

    const images = useSelector((state) => state.employerConstructor.images);

    return useMemo(
        () =>
            (items || [])
                .map(({ pictureId }) => images.find(({ pictureId: imagePictureId }) => pictureId === imagePictureId))
                .filter((image): image is EmployerConstructorImage => !!image)
                .filter(({ path }) => !!path)
                .map(({ path, pictureId, ...restInfo }) => ({
                    ...restInfo,
                    id: pictureId,
                    src: addCropParam(`${cdnHost}${path}`, isZp),
                })),
        [cdnHost, images, isZp, items]
    );
};

export default usePictures;
