import { useCallback, Fragment } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { WidgetComponentProps, EmployerConstructorSeparatorWidget } from 'src/models/employerConstructor';

import WidgetControls from 'src/components/EmployerConstructor/widgets/WidgetControls';
import WidgetWrapper from 'src/components/EmployerConstructor/widgets/WidgetWrapper';

import styles from './separator-widget.less';

const TrlKeys = {
    widgetSeparatorName: 'employer.constructor.widget.type.separator',
};

const SeparatorWidget: TranslatedComponent<WidgetComponentProps<EmployerConstructorSeparatorWidget>> = ({
    trls,
    editMode,
    id,
    getMovedElementProps,
    dragged,
}) => {
    const view = useCallback(() => {
        return <hr className={styles.widgetSeparator} data-qa="widget-separator" />;
    }, []);

    const edit = useCallback(() => {
        return (
            <Fragment>
                <WidgetControls
                    getMovedElementProps={getMovedElementProps}
                    name={trls[TrlKeys.widgetSeparatorName]}
                    upload={false}
                    edit={false}
                    id={id}
                />
                {view()}
            </Fragment>
        );
    }, [id, getMovedElementProps, trls, view]);

    return <WidgetWrapper dragged={dragged} edit={edit} view={view} editMode={editMode} viewReady />;
};

export default translation(SeparatorWidget);
