import { FC, ReactNode, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Button, { ButtonIconPosition } from 'bloko/blocks/button';
import { TrashScaleSmall, PenSquareScaleSmall, CameraScaleSmall, ArrowsScaleSmallKindDefault } from 'bloko/blocks/icon';

import { isNumber } from 'Utils/Utils';
import { GetMovedElementProps } from 'src/components/EmployerConstructor/drag/DragElement';
import { employerConstructorRemoveWidget } from 'src/models/employerConstructor';

import WidgetControlsButtonWrapper from 'src/components/EmployerConstructor/widgets/WidgetControlsButtonWrapper';

import styles from './widget-controls.less';

interface WidgetControlsProps {
    drag?: boolean;
    upload?: boolean;
    uploadOverride?: ReactNode;
    uploadText?: string;
    edit?: boolean;
    editText?: string;
    editOverride?: ReactNode;
    remove?: boolean;
    onUpload?: () => void;
    onEdit?: () => void;
    id?: number;
    name: string;
    getMovedElementProps?: GetMovedElementProps;
}

const WidgetControls: FC<WidgetControlsProps> = ({
    drag = true,
    upload = true,
    uploadOverride,
    uploadText,
    edit = true,
    editText,
    editOverride,
    remove = true,
    onUpload,
    onEdit,
    id,
    name,
    getMovedElementProps,
}) => {
    const dispatch = useDispatch();
    const onRemove = useCallback(() => {
        if (!isNumber(id)) {
            return;
        }
        dispatch(employerConstructorRemoveWidget(id));
    }, [dispatch, id]);

    return (
        <div className={styles.widgetControls}>
            <div>
                {drag && (
                    <div className={styles.widgetControlsMoveWrapper} {...getMovedElementProps?.()}>
                        <div className={styles.widgetControlsMoveOverlay}>
                            <Button icon={<ArrowsScaleSmallKindDefault />} />
                        </div>
                    </div>
                )}
                <span className={styles.widgetControlsName}>{name}</span>
            </div>
            <div>
                {upload && uploadOverride}
                {upload && !uploadOverride && (
                    <WidgetControlsButtonWrapper>
                        <Button onClick={onUpload} icon={<CameraScaleSmall />} iconPosition={ButtonIconPosition.Left}>
                            {uploadText}
                        </Button>
                    </WidgetControlsButtonWrapper>
                )}
                {edit && editOverride}
                {edit && !editOverride && (
                    <WidgetControlsButtonWrapper>
                        <Button onClick={onEdit} icon={<PenSquareScaleSmall />} iconPosition={ButtonIconPosition.Left}>
                            {editText}
                        </Button>
                    </WidgetControlsButtonWrapper>
                )}
                {remove && (
                    <WidgetControlsButtonWrapper>
                        <Button onClick={onRemove} icon={<TrashScaleSmall />} />
                    </WidgetControlsButtonWrapper>
                )}
            </div>
        </div>
    );
};

export default WidgetControls;
