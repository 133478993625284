import { FORBIDDEN } from 'http-status-codes';

import { isServerErrorWithKey } from 'src/utils/fetcher';

export enum EmployerConstructorError {
    ImageResized = 'EMPLOYER_CONSTRUCTOR_IMAGE_RESIZED',
    ResponseTimeout = 'EMPLOYER_CONSTRUCTOR_RESPONSE_TIMEOUT',
    InvalidSelectionLocation = 'INVALID_SELECTION_LOCATION',
    InvalidSelectionSize = 'INVALID_SELECTION_SIZE',
    InvalidSelectionRatio = 'INVALID_SELECTION_RATIO',
    EditNotPermitted = 'CONSTRUCTOR_EDIT_NOT_PERMITTED',
    PictureFileTooLarge = 'PICTURE_FILE_TOO_LARGE',
    PictureTooLarge = 'PICTURE_TOO_LARGE',
    BadPictureFile = 'BAD_PICTURE_FILE',
    UnsupportedPictureFileFormat = 'UNSUPPORTED_PICTURE_FILE_FORMAT',
    InvalidPictureSize = 'INVALID_PICTURE_SIZE',
}

const getErrorType = (error: unknown): string | undefined => {
    if (!isServerErrorWithKey(error)) {
        return undefined;
    }
    return error.response?.status === FORBIDDEN
        ? EmployerConstructorError.EditNotPermitted
        : error.response?.data?.error?.[0]?.key;
};

export default getErrorType;
