import { useEffect } from 'react';

export default (handler: (event: Event) => void): void => {
    useEffect(() => {
        window.addEventListener('scroll', handler);
        return () => {
            window.removeEventListener('scroll', handler);
        };
    }, [handler]);
};
